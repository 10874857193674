// project import
import myMenu from './myMenu';
// import pages from './pages';
// import dashboard from './dashboard';
import utilities from './utilities';
// import support from './support';

// ==============================|| MENU ITEMS ||============================== //
console.log();

//Se o myMenu[1].children.length tiver registro, significa que é adm, pois o myMenu[0] é pra todos, mas o myMenu[1] é só pro administrador do sistema
const menuItems = myMenu[1].children.length > 1 ? { items: [...myMenu, utilities] } : { items: [...myMenu] };
//items: [dashboard, pages, utilities, support]
//items: [...myMenu]

export default menuItems;
