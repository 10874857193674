// -----------------------------------------------------------API
import api from '../services/api';

const token = localStorage.getItem('token');

// Criação do objeto authorization
const authorization = {
  headers: {
    Authorization: `Bearer ${token}`
  }
};

const response = await api.get('/api/User/GetPermission', authorization);
const permGroupName = response.data || response;
// -----------------------------------------------------------API

// assets
import {
  DashboardOutlined,
  DingtalkOutlined,
  SolutionOutlined,
  IdcardOutlined,
  HomeOutlined,
  GlobalOutlined,
  ProfileOutlined,
  SettingOutlined,
  UnlockOutlined,
  UnorderedListOutlined,
  InsertRowLeftOutlined,
  UserOutlined,
  TeamOutlined,
  FacebookOutlined
} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  DingtalkOutlined,
  SolutionOutlined,
  IdcardOutlined,
  HomeOutlined,
  GlobalOutlined,
  ProfileOutlined,
  SettingOutlined,
  UnlockOutlined,
  UnorderedListOutlined,
  InsertRowLeftOutlined,
  UserOutlined,
  TeamOutlined,
  FacebookOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

//dashboard-----------------------------------------------------------------------------------
const dashboard = {
  id: 'group-dashboard',
  title: 'Sistema',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard/default',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    }
  ]
};

if (permGroupName === 'Administrador' || permGroupName === 'Empresa') {
  dashboard.children.push({
    id: 'corretores',
    title: 'Corretores',
    type: 'item',
    url: '/corretores',
    icon: icons.IdcardOutlined,
    breadcrumbs: false
  });
}

if (permGroupName === 'Administrador' || permGroupName === 'Empresa' || permGroupName === 'Corretor') {
  dashboard.children.push({
    id: 'condominium',
    title: 'Condomínios',
    type: 'item',
    url: '/condominios',
    icon: icons.InsertRowLeftOutlined,
    breadcrumbs: false
  });
}

if (permGroupName === 'Administrador' || permGroupName === 'Empresa' || permGroupName === 'Corretor') {
  dashboard.children.push({
    id: 'properties',
    title: 'Imóveis',
    type: 'item',
    url: '/imoveis',
    icon: icons.HomeOutlined,
    breadcrumbs: false
  });
}

if (permGroupName === 'Administrador' || permGroupName === 'Empresa' || permGroupName === 'Corretor') {
  dashboard.children.push({
    id: 'client',
    title: 'Clientes',
    type: 'item',
    url: '/clientes',
    icon: icons.TeamOutlined,
    breadcrumbs: false
  });
}

if (permGroupName === 'Administrador' || permGroupName === 'Empresa' || permGroupName === 'Corretor') {
  dashboard.children.push({
    id: 'leads',
    title: 'Leads',
    type: 'item',
    url: '/leads',
    icon: icons.UnorderedListOutlined,
    breadcrumbs: false
  });
}

if (permGroupName === 'Administrador' || permGroupName === 'Empresa' || permGroupName === 'Corretor') {
  dashboard.children.push({
    id: 'esteiraDeVendas',
    title: 'Esteira de Vendas',
    type: 'item',
    url: '/esteiradevendas',
    icon: icons.ProfileOutlined,
    breadcrumbs: false
  });
}

if (permGroupName === 'Ninguem') {
  dashboard.children.push({
    id: 'Integracoes',
    title: 'Integrações',
    type: 'item',
    url: '/integracoes  ',
    icon: icons.GlobalOutlined,
    breadcrumbs: false
  });
}

if (permGroupName === 'Administrador' || permGroupName === 'Empresa' || permGroupName === 'Corretor') {
  dashboard.children.push({
    id: 'Configuracoes',
    title: 'Configurações',
    type: 'item',
    url: '/configuracoes',
    icon: icons.SettingOutlined,
    breadcrumbs: false
  });
}

if (permGroupName === 'Administrador' || permGroupName === 'Empresa' || permGroupName === 'Corretor') {
  dashboard.children.push({
    id: 'redeSocial',
    title: 'Rede Social',
    type: 'item',
    url: '/redesocial',
    icon: icons.FacebookOutlined,
    breadcrumbs: false
  });
}

//supermanager-----------------------------------------------------------------------------------
const supermanager = {
  id: 'group-supermanager',
  title: permGroupName === 'Administrador' ? 'Configuração Administrativa' : '',
  type: 'group',
  children: []
};
if (permGroupName === 'Administrador') {
  supermanager.children.push(
    {
      id: 'user',
      title: 'Usuários',
      type: 'item',
      url: '/gerenciador/usuarios',
      icon: icons.UserOutlined,
      breadcrumbs: false
    },
    {
      id: 'permission',
      title: 'Grupo de Permissão',
      type: 'item',
      url: '/gerenciador/grupopermissao',
      icon: icons.UnlockOutlined,
      breadcrumbs: false
    },
    {
      id: 'host',
      title: 'Host',
      type: 'item',
      url: '/gerenciador/host',
      icon: icons.SettingOutlined,
      breadcrumbs: false
    },
    {
      id: 'inst',
      title: 'Instância',
      type: 'item',
      url: '/gerenciador/instancia',
      icon: icons.SettingOutlined,
      breadcrumbs: false
    },
    {
      id: 'data',
      title: 'Banco de dados',
      type: 'item',
      url: '/gerenciador/bancodedados',
      icon: icons.SettingOutlined,
      breadcrumbs: false
    }
  );
}

export default [dashboard, supermanager];
