import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Corretores = Loadable(lazy(() => import('pages/corretores')));
const Client = Loadable(lazy(() => import('pages/client')));
const Leads = Loadable(lazy(() => import('pages/leads')));
const Condominium = Loadable(lazy(() => import('pages/condominium')));
const Properties = Loadable(lazy(() => import('pages/properties')));
const EsteiraDeVendas = Loadable(lazy(() => import('pages/esteiraDeVendas')));
const Integracoes = Loadable(lazy(() => import('pages/integracoes')));
const Configuracoes = Loadable(lazy(() => import('pages/configuracoes')));
const RedeSocial = Loadable(lazy(() => import('pages/redeSocialMeta')));
// const NovoCorretor = Loadable(lazy(() => import('pages/novoCorretor')));

// render - superManager
const Host = Loadable(lazy(() => import('pages/superManager/host')));
const Instance = Loadable(lazy(() => import('pages/superManager/instance')));
const Database = Loadable(lazy(() => import('pages/superManager/database')));
const GrupoPermissao = Loadable(lazy(() => import('pages/superManager/permissionGroup')));
const User = Loadable(lazy(() => import('pages/superManager/user')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },

    {
      path: 'corretores',
      element: <Corretores />
    },

    {
      path: 'clientes',
      element: <Client />
    },

    {
      path: 'leads',
      element: <Leads />
    },

    {
      path: 'condominios',
      element: <Condominium />
    },

    {
      path: 'imoveis',
      element: <Properties />
    },

    {
      path: 'esteiradevendas',
      element: <EsteiraDeVendas />
    },

    {
      path: 'integracoes',
      element: <Integracoes />
    },

    {
      path: 'configuracoes',
      element: <Configuracoes />
    },

    {
      path: 'redesocial',
      element: <RedeSocial />
    },

    {
      path: 'gerenciador/host',
      element: <Host />
    },

    {
      path: 'gerenciador/instancia',
      element: <Instance />
    },

    {
      path: 'gerenciador/bancodedados',
      element: <Database />
    },

    {
      path: 'gerenciador/grupopermissao',
      element: <GrupoPermissao />
    },

    {
      path: 'gerenciador/usuarios',
      element: <User />
    },

    {
      path: 'color',
      element: <Color />
    },

    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },

    {
      path: 'sample-page',
      element: <SamplePage />
    },

    {
      path: 'shadow',
      element: <Shadow />
    },

    {
      path: 'typography',
      element: <Typography />
    },

    {
      path: 'icons/ant',
      element: <AntIcons />
    }
  ]
};

export default MainRoutes;
