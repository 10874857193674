import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.habfide.com.br'
});

//export default api;

// Adiciona um interceptor para cada requisição
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    //console.log('Adiciona um interceptor para cada requisição')
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Adiciona um interceptor para cada resposta
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token expirado, redireciona para a página de login
      if (window.location.pathname != '/login') window.location.href = `${window.location.origin}/login`;
      else return '=(';
    }
    //console.log('Adiciona um interceptor para cada resposta')
    return Promise.reject(error);
  }
);

export default api;
